import React, {Component} from 'react';
import ReactMapGL, {Marker, NavigationControl, Popup} from 'react-map-gl';

import WebMercatorViewport from 'viewport-mercator-project';
import bbox from '@turf/bbox';

import STATES from '../data/us-states.json';

import ClinicPin from './clinic-pin';
import ClinicInfo from './clinic-info';

import {defaultMapStyle} from './map-style.js';
import CommunityResourceInfo from './community-resource-info.js';

const navStyle = {
  position: 'absolute',
  top: 36,
  left: 0,
  padding: '10px'
};

const noShowNav = {
  position: 'absolute',
  top: 36,
  left: 0,
  padding: '10px',
  display: "none"
}

export default class Map extends Component {

  state = {
    selectedClinic: null,
    year: 2015,
    data: null,
    hoveredFeature: null,
    viewport: {
      width: '100%',
      height: '100%',
      latitude: 40,
      longitude: -100,
      zoom: 3,
      bearing: 0,
      pitch: 0
    }
  };

  render() {
    const {data, singular} = this.props;
    const {viewport} = this.state;
    const mapStyle = defaultMapStyle;

    const validLocationData = data.filter(item => 
      item.getIn(['location', 'latitude']) && 
      item.getIn(['location', 'longitude'])
    );

    return (
      <ReactMapGL
        scrollZoom={!singular}
        defaultWidth="100%"
        {...viewport}
        mapStyle={mapStyle}
        ref={(reactMap) => this.reactMap = reactMap}
        onViewportChange={(viewport) => this.setState({viewport})}
        mapboxApiAccessToken={process.env.GATSBY_MAPBOX_ACCESS_TOKEN}
      >
        {validLocationData.map(this._renderClinicMarker)}

        {this._renderPopup()}

        <div className="nav" style={navStyle}>
          <NavigationControl />
        </div>

      </ReactMapGL>
    );
  }

  _renderClinicMarker = (clinic, index) => {
    return (
      <Marker key={`marker-${index}`} longitude={clinic.getIn(['location', 'longitude'])} latitude={clinic.getIn(['location', 'latitude'])}>
        <ClinicPin size={20} onClick={() => this.setState({selectedClinic: clinic})} />
      </Marker>
    );
  };

  _renderPopup() {
    const {selectedClinic} = this.state;
    const {resourceType} = this.props;

    return (
      selectedClinic && (
        <Popup
          tipSize={5}
          anchor="bottom"
          longitude={selectedClinic.getIn(['location', 'longitude'])}
          latitude={selectedClinic.getIn(['location', 'latitude'])}
          closeOnClick={false}
          onClose={() => this.setState({selectedClinic: null})}
        >
          {resourceType === 'clinic' ? (
            <ClinicInfo clinic={selectedClinic} />
          ) : (
            <CommunityResourceInfo resource={selectedClinic} />
          )}
        </Popup>
      )
    );
  }

  componentDidMount() {
    setTimeout(() => {
      this.animateToSelected({ selectedState: "none" });
    })
  }

  componentDidUpdate(prevProps) {
    this.animateToSelected(prevProps);
  }

  animateToSelected(props) {
    // console.log("this.props",this.props)
    // console.log("props", props)
    const {selectedState, selectedCoordinates} = this.props;
    if (selectedState && selectedState !== "null" && selectedState !== props.selectedState) {
      this.animateToState(selectedState);
    } else if (selectedCoordinates && (selectedCoordinates !== props.selectedCoordinates)) {
      setTimeout(() => {
        this.setState({
          viewport: {
            ...this.state.viewport,
            longitude: selectedCoordinates.coordinates[0],
            latitude: selectedCoordinates.coordinates[1],
            zoom: 8,
            transitionDuration: 1000
          }
        });
      })
    }
  }

  animateToState(stateName) {
    const stateFeature = STATES.features.find(({properties: {name}}) => name === stateName);
    if (stateFeature) {
      const [minLng, minLat, maxLng, maxLat] = bbox(stateFeature);
      const viewport = new WebMercatorViewport(this.state.viewport);
      const {longitude, latitude, zoom} = viewport.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
        padding: 0
      });
      if(!this.props.singular){
        this.setState({
          viewport: {
            ...this.state.viewport,
            longitude,
            latitude,
            zoom,
            transitionDuration: 1000
          }
        });
      } else {
        this.setState({
          viewport: {
            ...this.state.viewport,
            longitude,
            latitude: latitude - 1,
            zoom: zoom,
            transitionDuration: 1000
          }
        });
      }

    } else {
      this.setState({
        viewport: {
          ...this.state.viewport,
          latitude: 40,
          longitude: -100,
          zoom: 3,
          transitionDuration: 1000
        }
      });
    }
  }
}
