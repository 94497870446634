import React, { useEffect, useState } from "react"
import { IdealSplitTabs, IdealTabs } from "../../vendor/mui-pro/src/components/CustomTabs/CustomTabs"
import { makeStyles, Typography } from "@material-ui/core"
import { CustomButton } from "../CustomButtons/CustomButton"
import StateSelect from "../Selects/StateSelect"
import Colors from "../../constants/Colors"
import { createStyles } from "@material-ui/styles"
import clsx from "clsx"
import Map from "../map"
import { fromJS } from "immutable"
import CurrentLocationFinder from "../CurrentLocationFinder"
import {distance} from '@turf/turf'
import { Link, graphql, useStaticQuery } from "gatsby"
import customSelectStyles from "../../vendor/mui-pro/src/assets/jss/material-kit-pro-react/customSelectStyle"
import List from "@material-ui/icons/List";
import MapIcon from "@material-ui/icons/Map";
import Button from "../../vendor/mui-pro/src/components/CustomButtons/Button"
import { getDirections } from "../../hooks/routing"
import { standardPadding } from "../../constants/Spacing"

const selectStyles = makeStyles(customSelectStyles);

const useStyles = makeStyles((theme) => createStyles({
  cardOuterContainer: {
    paddingTop: 10,
    backgroundColor: Colors.pickledBluewood,
    ...standardPadding.standardWidgetBottomPadding,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      ...standardPadding.standardWidgetBottomPaddingMobile,
    }
  },
  customCardBody:{  //card root style to apply
    height: "fit-content",
    "& > div":{
      height: "100%",
    },
  },
  noPadding: {
    padding: 0,
  },
  secondTab: {
    [theme.breakpoints.down("sm")]:{
      padding: "0px 15pt 12px 15pt"
    },
    display: "flex",
    alignItems: "center",
    borderRadius: 2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      //marginBottom: "-23px",
      marginBottom: "-4px",
    },
    "& > *:not(:first-child)" : {
      marginLeft: 10
    },
  },
  visibleMedium: {
    [theme.breakpoints.down("sm")]:{
      display: "none"
    },
  },
  visibleSmall: {
    [theme.breakpoints.up("md")]:{
      width: "calc(50% - 14px)",
      height: 79,
      transform: `translate(107%, -79px)`,
      backgroundColor: Colors.kashmirBlue,
      borderRadius: "6px",
      //marginBottom: "-95px"
      marginBottom: -74,
    },
  },
  selectListItemContainer: {
    marginTop: 14,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 42,
    "& > div:nth-child(odd)": {
      backgroundColor: Colors.catskillWhite,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    }
  },
  innerIcon: {
    color: Colors.azure,
  },
  selectListItem: {
    display: "flex",
    borderBottom: `1px solid ${Colors.horizontalUnderlineGray}`,
    paddingTop: "20px",
    paddingRight: "19px",
    paddingBottom: "20px",
    paddingLeft: "28px",
    [theme.breakpoints.down("sm")]:{
      flexDirection: "column",
      alignItems: "flex-start"
    },

    [theme.breakpoints.up("md")]:{
      flexDirection: "row",
      justifyContent: "space-between",
    },

    "& button": {
      marginLeft: "10px"
    }
  },
  mapStyleAnchor: {
    height: "550px",
    width: "100%",
    overflow: "hidden",
    "& > div": {
      height: '100%',
      width: "100% !important",
    },
    "& .mapboxgl-map": {
      borderRadius: "6px",
      "& + div": {
        height: "100% !important",
        width: "100% !important",
      },
    },
    "& > div:nth-child(2)": {
      width: "100% !important", //Needed to resize map.
      maxHeight: "430px"  //Prevent map overflow
    }
  },
  input: {
    color: Colors.pickledBluewood,
  },
  select: {  //Select field itself
    borderRadius: "2px",
    backgroundColor: Colors.white,
    // minWidth: "300px",
    '[class*="MuiSvgIcon-root"]': {
      color: Colors.white
    },
    "&:focus": {
      backgroundColor: Colors.white,
    },
  },
  selectPrimary: {
    paddingLeft: 10,
    border: `2px solid ${Colors.kashmirBlue}`,
    width: "100%",
    backgroundColor: Colors.white,
    //Native Select styling for Map dropdown
    textTransform: props => props.native ? "capitalize" : "none",
    color: Colors.kashmirBlue,
    fontWeight: "600",
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      textTransform: "none",
      padding: "12px 0 10px 7px",
      fontFamily: "Lato, Verdana, Arial, sans-serif",
    }
  },
  selectMenu: {  //select menu styling
    "& > div": {
      backgroundColor: Colors.white,
    },
    "& > div > ul": {
      backgroundColor: Colors.white,
    },
    "& h6": {
      color: `${Colors.azure} !important`,
    },
  },
  selectMenuPrimary: {  //select menu styling
    "& > div": {
      backgroundColor: Colors.white,
    },
    "& > div > ul": {
      backgroundColor: Colors.white,
    },
  },
  selectMenuItem: {
    fontFamily: "Calluna",
    color: Colors.mineShaft,
    paddingLeft: 12,
    fontSize: 16,
    textTransform: "none",
    "&>:h6": {
      fontFamily: "Calluna !important"
    },
    "&:hover": {
      //backgroundColor: Colors.grayChateau + "!important",
      backgroundColor: `rgba(43, 103, 214, .1) !important`,
      boxShadow: "none",
    },
  },
  buttonDiv:{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down('sm')]:{
      transform: "translateX(-10px)",
    }
  },
  stateBanner:{
    height: 37,
    backgroundColor: Colors.grayChateau,
    color: Colors.inverted,
    fontFamily: "Lato, Verdana, Arial, sans-serif",
    paddingTop: 7,
    paddingLeft: 28,
    fontWeight: "bold",
  }
}))

export const humanizeDistance = (distance) => {
  if (!distance) return;
  return parseInt(distance) === 1 ? `${parseInt(distance)} mile away` : `${parseInt(distance)} miles away`;
}


export default function ({ location }) {
  const {selectedState, currentPositionPoint} = location.state || {};
  const [internalSelectedState, setInternalSelectedState] = useState(selectedState);
  const [isRetrievingPosition, setIsRetrievingPosition] = useState(false);

  const data = useStaticQuery(graphql`
      query ClinicsQuery {
          allDatoCmsClinic {
              edges {
                  node {
                      id
                      title
                      slug
                      address
                      state
                      location {
                          latitude
                          longitude
                      }
                  }
              }
          }
      }
  `)

  const iOS = typeof(navigator) !== "undefined" && typeof(window) !== "undefined" ? /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream : false;

  const mappedClinics = data.allDatoCmsClinic.edges.flatMap(e => e.node);
  const [allClinics, setAllClinics] = useState(fromJS(mappedClinics).sortBy(c => c.get('title')));
  const [internalCurrentPositionPoint, setInternalCurrentPositionPoint] = React.useState(currentPositionPoint);

  const clinicsByState = () => {
    return allClinics.sortBy(c=> c.get('state'))
  }

  let clinicsWithDistance = !internalCurrentPositionPoint ? clinicsByState() : allClinics
    .map(c => c.set('distance', distance(internalCurrentPositionPoint, {
        "type": "Point",
        "coordinates": [ c.getIn(['location', 'longitude']), c.getIn(['location', 'latitude']) ]
      }, { units: 'miles' }))
    )
    .sortBy(c => c.get('distance'))

  let filteredClinics = (internalSelectedState === 'none') ? clinicsWithDistance : clinicsWithDistance
    .filter(f => (!internalSelectedState || internalSelectedState === f.get('state')));

  const selectClasses = selectStyles();
  const classes = useStyles({native: iOS});

  let priorClinic = " "

  const checkPriorState = (clinic) => {
    if(clinic.get('state') !== priorClinic){
      priorClinic = clinic.get('state');
      return true
    } else {
      return false
    }
  }

  return(
    <div className={classes.cardOuterContainer}>
      <IdealSplitTabs
      noPadding
      classes={{root: classes.root,
        customCardBody: classes.customCardBody,
        noPadding: classes.noPadding
      }}
      tabStyle={{color: "white"}}
      tabsContainer={classes.tabsContainer}
      customTabPanel={
        <div className={clsx(classes.secondTab, classes.visibleSmall, "svg-override-clinic-map")}>
          <CurrentLocationFinder
            classes={{
              innerIcon: classes.innerIcon
            }}
            isRetrievingPosition={isRetrievingPosition}
            onStateSelected={setInternalSelectedState}
            onGetCurrentPosition={setInternalCurrentPositionPoint}
          />
          <StateSelect
            className="state-select-override"
            primary
            blueText
            defaultColor={Colors.mineShaft}
            textColor={Colors.mineShaft}
            value={internalSelectedState || "none"}
            updateValue={setInternalSelectedState}
            rootStyle={{flexGrow: 1}}
            selectMenuProps={{
              className: clsx(selectClasses.selectMenu, classes.selectMenu, classes.selectMenuPrimary)
            }}
            selectClasses={{
              select: clsx(selectClasses.select, classes.select, classes.selectPrimary)
            }}
            menuItemClasses={{
              root: clsx(selectClasses.selectMenuItem, classes.selectMenuItem),
              input: classes.input
            }}
            iOS={iOS}
          />
        </div>
      }
      tabs={
        [
          {
            tabName: "List View",
            tabIcon: List,
            tabContent: (
              <div className={classes.selectListItemContainer}>
                {
                  filteredClinics.map(function(clinic) {
                    return(
                    <div>
                        {
                          (!internalCurrentPositionPoint && checkPriorState(clinic)) &&
                          <div className={classes.stateBanner}>{clinic.get("state")}</div>
                        }
                    <div className={classes.selectListItem}>
                      <div style={{display: "flex", flexDirection: "column"}}>
                        <Typography variant="h3" style={{color: Colors.mineShaft, width: "100%", marginBottom: 10}}>{clinic.get('title')}</Typography>
                        {
                          isRetrievingPosition &&
                          <Typography variant="h6">Loading...</Typography>
                        }
                        { clinic.get('distance') &&
                          <Typography style={{marginBottom: 5}} variant="h5">{humanizeDistance(clinic.get('distance'))}</Typography>
                        }
                        <Typography style={{fontSize: 12, maxWidth: 170, color: Colors.tundora, fontWeight: 400}} variant="h6">{clinic.get('address')}</Typography>
                      </div>
                      <div className={classes.buttonDiv}>
                        <Link to={`/suboxone-clinics/${clinic.get('slug')}`} style={{textDecoration: "none", alignSelf: "center"}}>
                          <Button color="primary" size="sm" >
                            <Typography variant="button">Details</Typography>
                          </Button>
                        </Link>
                        <Button onClick={() => getDirections(clinic.get("address"))} color="secondary" size="sm" style={{
                          color: Colors.azure,
                          marginRight: 10,
                          marginLeft: 12,
                        }}>
                          <div>{clinic.address}</div>
                          <Typography variant="button">Get directions</Typography>
                        </Button>
                      </div>
                    </div>
                      </div>
                        )
                  })
                }
              </div>
            )
          },
          {
            tabName: "Map View",
            tabIcon: MapIcon,
            tabContent: (
              <div className={classes.mapStyleAnchor} >
                <Map
                data={filteredClinics}
                selectedState={internalSelectedState}
                selectedCoordinates={internalCurrentPositionPoint}
                resourceType="clinic"
                />
              </div>
            )
          }
        ]
      }
    />
    </div>
  )
}
