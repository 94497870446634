import React, {PureComponent} from 'react';
import { Typography } from "@material-ui/core"
import Colors from "../constants/Colors.js"
import { CustomButton } from "./CustomButtons/CustomButton.jsx"
import { Link } from "gatsby"
import {humanizeDistance} from "./widgets/ClinicMap.js"
import Button from '../vendor/mui-pro/src/components/CustomButtons/Button.jsx';
import { getDirections } from "../hooks/routing.js"


export default class CommunityResourceInfo extends PureComponent {
  render() {
    const {resource} = this.props;

    return (
      <div style={{
        padding: "18px 18px 5px",
      }}>
        <Typography variant="h3" style={{color: Colors.mineShaft, width: "100%", marginBottom: 10}}>{resource.get('title')}</Typography>
        <Typography style={{marginBottom: 5}} variant="h5">{humanizeDistance(resource.get('distance'))}</Typography>
        <Typography style={{maxWidth: 170, color: Colors.tundora, fontWeight: 400}} variant="h6">{resource.get('address')}</Typography>
        <Button 
          onClick={() => window.open(resource.get('websiteUrl'), '_blank')} 
          color="secondary" 
          size="sm" 
          style={{
            color: Colors.azure,
            marginLeft: 12,
            boxShadow: "none"
          }}
        >
          <Typography variant="button">Visit Website</Typography>
        </Button>
        <Button 
          onClick={() => window.location.href = `tel:${resource.get('phone')}`} 
          color="primary" 
          size="sm"
          style={{
            alignSelf: "center",
            marginRight: 10,
            marginLeft: 12,
              boxShadow: "none",
          }}
        >
          <Typography variant="button">Call</Typography>
        </Button>
      </div>
    );
  }
}
